import React, { forwardRef, Ref, CSSProperties, ReactNode } from 'react';
import { gql } from '@apollo/client';
import classNames from 'classnames';
import { Button } from '@moda/om';
import { CtaType, PumoModuleEditorialTextFragment } from '../../generated/types';
import { useEditorialTextStyle, USE_EDITORIAL_TEXT_STYLE_FRAGMENT } from './useEditorialStyle';
import { PumoModuleEditorialTextElement } from './PumoModuleEditorialTextElement';

import './PumoModuleEditorialText.scss';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  textFragment: PumoModuleEditorialTextFragment;
  ctaType: CtaType;
  beforeEyebrow?: ReactNode;
  afterEyebrow?: ReactNode;
  normalizePumoStyles?: boolean;
  spaceBetween?: boolean;
  contentStyle?: CSSProperties;
}

export const PUMO_MODULE_EDITORIAL_TEXT_FRAGMENT = gql`
  fragment PumoModuleEditorialTextFragment on SlideText {
    ctaText
    headlineText {
      body
    }
    taglineText {
      body
    }
    eyebrowText {
      body
    }
    ...UseEditorialTextStyleFragment
  }
  ${USE_EDITORIAL_TEXT_STYLE_FRAGMENT}
`;

export const PumoModuleEditorialText = forwardRef(
  (
    {
      ctaType,
      beforeEyebrow = null,
      afterEyebrow = null,
      normalizePumoStyles,
      spaceBetween,
      textFragment,
      textFragment: { eyebrowText, headlineText, taglineText, ctaText },
      className,
      children,
      contentStyle
    }: Props,
    ref: Ref<HTMLDivElement>
  ) => {
    const { alignItems, justifyContent, ...styles } = useEditorialTextStyle(textFragment);

    return (
      <div
        className={classNames('PumoModuleEditorialText', className)}
        style={{ justifyContent, alignItems }}
      >
        <div
          className={classNames('PumoModuleEditorialText__text', {
            'PumoModuleEditorialText__text--space-between': spaceBetween
          })}
          ref={ref}
          style={{ ...styles, ...contentStyle }}
        >
          {beforeEyebrow}
          {eyebrowText && (
            <PumoModuleEditorialTextElement
              normalizePumoStyles={normalizePumoStyles}
              kind="eyebrow"
            >
              {eyebrowText.body}
            </PumoModuleEditorialTextElement>
          )}

          {afterEyebrow}

          {headlineText?.body && (
            <PumoModuleEditorialTextElement
              normalizePumoStyles={normalizePumoStyles}
              kind="headline"
            >
              {headlineText.body}
            </PumoModuleEditorialTextElement>
          )}

          {taglineText && (
            <PumoModuleEditorialTextElement
              normalizePumoStyles={normalizePumoStyles}
              kind="tagline"
            >
              {taglineText.body}
            </PumoModuleEditorialTextElement>
          )}

          {ctaText && ctaType === CtaType.BUTTON ? (
            <Button className="PumoModuleEditorialText__cta-button" tertiary>
              {ctaText}
            </Button>
          ) : (
            <span className="PumoModuleEditorialText__cta">{ctaText}</span>
          )}

          {children}
        </div>
      </div>
    );
  }
);

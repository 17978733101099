import React from 'react';
import { useLocation } from 'react-router-dom';
import { gql } from '@apollo/client';
import classNames from 'classnames';
import { CarouselPumoModuleFragment } from '../../generated/types';
import { hrefFor } from '../../routers';
import { useModuleTracking } from '../../hooks/useModuleTracking';
import {
  CarouselPumoModuleSlider,
  CAROUSEL_PUMO_MODULE_SLIDER_FRAGMENT
} from '../CarouselPumoModuleSlider';
import {
  CarouselPumoModuleGrid,
  CAROUSEL_PUMO_MODULE_GRID_FRAGMENT
} from '../CarouselPumoModuleGrid';
import { CarouselPumoModuleCategories } from '../CarouselPumoModuleCategories';
import { RestrictPlatform } from '../RestrictPlatform';
import { CAROUSEL_DEFAULT_CONFIG, CAROUSEL_NARROW_CONFIG } from '../Swiper';
import { PUMO_MODULE_SLIDE_FRAGMENT, PumoModuleSlide } from '../PumoModuleSlide';
import { NormalizePumoStyles } from '../NormalizePumoStyles';

import './CarouselPumoModule.scss';

export const CAROUSEL_PUMO_MODULE_FRAGMENT = gql`
  fragment CarouselPumoModuleFragment on CarouselPumoModule {
    name
    id
    layoutType
    modelId
    platform
    mainEditorial {
      ...PumoModuleSlideFragment
    }
    ...CarouselPumoModuleSliderFragment
    ...CarouselPumoModuleGridFragment
  }
  ${CAROUSEL_PUMO_MODULE_SLIDER_FRAGMENT}
  ${CAROUSEL_PUMO_MODULE_GRID_FRAGMENT}
  ${PUMO_MODULE_SLIDE_FRAGMENT}
`;

enum LayoutType {
  Carousel = 1,
  Grid = 2
}

const useCarouselModuleType = (carouselPumoModule: CarouselPumoModuleFragment) => {
  const { pathname } = useLocation();

  /**
   * This is a temporary workaround for getting the category tiles styled correctly on the Beauty Landing Page.
   * TODO: Implement proper support for this in our CMS.
   */
  if (
    (pathname === hrefFor.BeautyLandingPage() ||
      pathname === hrefFor.HomeLandingPage() ||
      pathname === hrefFor.EditorialPage({ slug: 'beauty-test-page' })) &&
    carouselPumoModule.name === 'Categories'
  )
    return 'categories';

  if (carouselPumoModule.layoutType === LayoutType.Grid) return 'grid';

  return 'carousel';
};

interface Props {
  carouselPumoModule: CarouselPumoModuleFragment;
}

export const CarouselPumoModule: React.FC<Props> = ({ carouselPumoModule }) => {
  const type = useCarouselModuleType(carouselPumoModule);

  const { ref, onClick } = useModuleTracking({
    name: carouselPumoModule.name,
    attachClickHandler: false,
    variants: carouselPumoModule.cells
  });

  const hasMainEditorial =
    Boolean(carouselPumoModule.mainEditorial?.featureImage?.imageSrc) ||
    Boolean(carouselPumoModule.mainEditorial?.slideText?.taglineText?.body);

  return (
    <RestrictPlatform platforms={carouselPumoModule.platform}>
      <div
        ref={ref}
        className={classNames('CarouselPumoModule', {
          'CarouselPumoModule--editorial': hasMainEditorial
        })}
      >
        {carouselPumoModule.mainEditorial && hasMainEditorial && (
          <NormalizePumoStyles className="CarouselPumoModule__editorial">
            <PumoModuleSlide slide={carouselPumoModule.mainEditorial} />
          </NormalizePumoStyles>
        )}

        <div className="CarouselPumoModule__content">
          {type === 'categories' && (
            <CarouselPumoModuleCategories
              carouselPumoModule={carouselPumoModule}
              onClick={onClick}
            />
          )}
          {type === 'grid' && (
            <CarouselPumoModuleGrid carouselPumoModuleGrid={carouselPumoModule} onClick={onClick} />
          )}
          {type === 'carousel' && (
            <CarouselPumoModuleSlider
              carouselPumoModuleSlider={carouselPumoModule}
              moduleName={carouselPumoModule.name}
              config={hasMainEditorial ? CAROUSEL_NARROW_CONFIG : CAROUSEL_DEFAULT_CONFIG}
              onClick={onClick}
            />
          )}
        </div>
      </div>
    </RestrictPlatform>
  );
};
